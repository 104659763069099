import Vue from 'vue'
import App from '@/App.vue'
import router from '@/index'

import "@/assets/my.global.css"
import "@/assets/bootstrap/5.3.3/css/bootstrap.min.css"
import store from "@/store/store"
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
// 头像裁剪组件
import VueCropper from 'vue-cropper'
// 文本编辑器
import VueQuillEditor from 'vue-quill-editor'
import 'element-ui/lib/theme-chalk/index.css';
import ElementUI from 'element-ui'

Vue.use(ElementUI)
Vue.use(VueCropper)
Vue.use(VueQuillEditor);
// todo errorHandler 汇报js异常
// Vue.config.errorHandler = function (err, vm, info) {
//   console.error('error---', err)
//   console.info('vm---', vm)
//   console.info('info---', info)
// }

new Vue({
    router,
    store,
    render: function (h) {
        return h(App)
    }
}).$mount('#app')
