export const MemberRootUrl = "/member"

export const MemberAdminHome = "/member/admin"
export const GoodsList = "/member/admin/GoodsList"
export const Order = "/member/admin/Order"
export const GoodsAdd = "/member/admin/GoodsAdd"
export const GoodsEdit = "/member/admin/GoodsEdit"
export const Qrcode = "/member/admin/Qrcode"
export const FoodInfo = "/member/admin/OrderInfo"

const routes = [
    {
        path: MemberAdminHome,
        component: () => import('@/views/member/admin/MemberAdminApp.vue'),
        redirect: GoodsList,
        children: [
            {
                path: GoodsList,
                component: () => import('@/views/member/admin/GoodsPage.vue')
            },
            {
                path: Order,
                component: () => import('@/views/member/admin/OrderPage.vue')
            },
            {
                path: GoodsAdd,
                component: () => import('@/views/member/admin/GoodsAdd')
            },
            {
                path: GoodsEdit,
                component: () => import('@/views/member/admin/GoodsEdit')
            },
            {
                path: Qrcode,
                component: () => import('@/views/member/admin/Qrcode')
            },
        ]
    },

]
export default routes

